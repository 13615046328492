#contactPage{
    min-height: calc(100vh - 35rem);
    width: 100vw;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem !important;
}
.title{
    margin: 1rem 0;
    font-size: 3rem;
}
.disc{
    font-weight: 200;
        font-size: 1.5rem;
        max-width: 51rem;
        padding: 0 2rem;
}
.techImgs{
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
}
.tech{
    object-fit: cover;
    width: 23%;
    min-width: 9rem;
    margin: 2rem auto;
    padding: 0.25rem;
    background: rgb(50, 50, 50);
    border-radius: 0.5rem;
}
#contact{
    min-height: calc(100vh - 35rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem !important;
}

.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 60rem;
}
.name, .email, .number,.message{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 1.25rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(50, 50, 50);
}
.submit{
    background: white;
    padding: 0.75rem 3.5rem;
    border: none;
    border-radius: 0.5rem;
    margin: 1rem;
}
.links{
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}
.link{
    height: 2rem;
    width: 2rem;
    margin: 0 0.75rem;
    object-fit: cover;
}
