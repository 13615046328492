#works{
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    padding: 0 1rem;
    margin-top: 6rem !important;
}
.workTitle{
    margin: 1rem 0;
    font-size: 3rem;
}
.workDesc{
    font-weight: 200;
        font-size: 1.5rem;
        max-width: 51rem;
        padding: 0 2rem;
}
.workImgs{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45vw;
    max-width: 45rem;
    flex-wrap: wrap;
    margin-top: 2rem;
}
.workImg{
    object-fit: cover;
    height: 20rem;
    margin: 0.5rem;
    border-radius: 0.75rem;
}

@media screen and (max-width:580) {
    .workImg{
        height: 48vw;
    }
    
}