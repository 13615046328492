.footer{
    width: 100vw;
    height: 4rem;
    background: #16b08a;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: 300;
    font-size: medium;
    color: white;
    margin-top: 2rem;
    align-items: center;
}