#skills{
    overflow: hidden;
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-top: 6rem !important;
}
.skillTitle{
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}
.skillDescriotion{
        font-weight: 200;
        font-size: 1.5rem;
        max-width: 51rem;
        padding: 0 2rem;
}
.skillBars{
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}
.skillBar{
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: rgb(50, 50, 50);
}
.skillBarImg{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
}
.skillBartext>P{
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.75rem;
    padding-top: 0.25rem;
}

@media screen and (max-width:720px) {
    .skillBartext>P{
        font-size: 2vw;
    }
    
}
@media screen and (max-width:480px) {
    .skillBartext>P{
        font-size: 3vw;
    }
    .skillBarImg{
        height: 2.25rem;
        width: 2.25rem;
    }
    .skillBartext>h2{
        font-size: 5vw;
    }
}