.navbar{
    height: 5rem;
    width: 100vw;
    max-width: 100%;
    background: rgb(0, 0, 0);
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}
.logo{
    object-fit: cover;
    height: 4rem;
    width: auto;
}
.desktopMenuListItems{
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItems:hover{
    color: #16b08a; 
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #16b08a;
}


.desktopMenuBtn{
    background: #fff;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
}


.desktopMenuImg{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem;
}
.active{
    color: #16b08a;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #16b08a;
}
.navMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40, 40, 40);
    border-radius: 1rem;
}
.ListItems{
    color: #fff;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30);
}

.mobMenu{
    display: none;
    object-fit: cover;
    height: 2rem;
}


@media screen and (max-width: 720px) {
    .mobMenu{
        display: flex;
    }
    .desktopMenu{
        display: none;
    }
    .desktopMenuBtn{
        display: none;
    }
}