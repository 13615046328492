#intro{
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}
.bg{
    position: absolute;
    z-index: -1;
    top: -4rem;
    right: 0;
    object-fit: cover;
    height: 100vh;
}
.overlay-fade{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0,36,12,0) 50%, rgba(0,0,0,1)100%);
}

.introContent{
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    max-width: 80%;
    margin: 0 auto;
    z-index: 10;
}

.hello{
    font-size: 1.7rem;
    font-weight: 100;
    z-index: 10;
}

.introName{
    color: #16b08a;
}

.introPhara{
    font-size: 1.26rem;
    font-weight: 300;
    line-height: 1.5rem;
    padding-top: .5rem;
    letter-spacing: 1px;
    max-width: 50rem;
    z-index: 10;
}
.btn{
    background: white;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    z-index: 10;
}
.btn > span{
    font-size: 1.15rem;
    padding-left: 0.25rem;
    font-weight: 300;
    z-index: 10;
}

.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1.15rem;
}

@media screen and (max-width:840px){
    .bg{
        right: -10vw;
    }
    .introContent{
        font-size: 10vw;
    }
    .hello{
        font-size: 4.5vw;
    }
    
}

@media screen and (max-width:480px){
    .bg{
        right: -20vw;
    }
}